import { graphql } from 'gatsby';
import { shape } from 'prop-types';
import React from 'react';
import Card from '../components/card/card';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';
import Hero from '../components/hero/hero';
import Layout from '../components/layout';
import SidebarMedications from '../components/sidebar-medications/sidebar-medications';
import MedIconC1 from '../images/med-icon-c1.png';
import MedIconRetinaC1 from '../images/med-icon-c1@2x.png';
import MedIconC2 from '../images/med-icon-c2.png';
import MedIconRetinaC2 from '../images/med-icon-c2@2x.png';
import MedIconC3 from '../images/med-icon-c3.png';
import MedIconRetinaC3 from '../images/med-icon-c3@2x.png';
import MedIconC4 from '../images/med-icon-c4.png';
import MedIconRetinaC4 from '../images/med-icon-c4@2x.png';
import MedIconDefault from '../images/med-icon.png';
import MedIconRetinaDefault from '../images/med-icon@2x.png';
import formatGraphqlData from '../utils/gatsbyGraphql';

const propTypes = {
  data: shape({}).isRequired,
};

const MedicationTemplate = ({ data }) => {
  const {
    allStrapiMedication,
    site: { siteMetadata },
    strapiMedication: {
      name,
      strapiId,
      deaSchedule,
      presentation,
      productCategory,
      releaseCategory,
      shortDescription,
      moreInformation,
    },
  } = data;
  const allMedications = allStrapiMedication ? formatGraphqlData(allStrapiMedication) : [];

  let MedIcon = null;
  let MedIconRetina = null;

  switch (parseInt(deaSchedule, 10)) {
    case 1:
      MedIcon = MedIconC1;
      MedIconRetina = MedIconRetinaC1;
      break;
    case 2:
      MedIcon = MedIconC2;
      MedIconRetina = MedIconRetinaC2;
      break;
    case 3:
      MedIcon = MedIconC3;
      MedIconRetina = MedIconRetinaC3;
      break;
    case 4:
      MedIcon = MedIconC4;
      MedIconRetina = MedIconRetinaC4;
      break;
    default:
      MedIcon = MedIconDefault;
      MedIconRetina = MedIconRetinaDefault;
      break;
  }

  return (
    <Layout>
      <Hero backgroundImage="zoo-pharm" headline={name} tagline="" />
      <section className="content-wrapper">
        <Grid>
          <GridItem sm={4} md={3}>
            <SidebarMedications title="All Formulations" medications={allMedications} />
          </GridItem>
          <GridItem sm={8} md={9}>
            <Grid justifyContent="space-between" alignItems="center">
              <GridItem md={4}>
                <div className="c-result-item c-result-item-single">
                  <picture>
                    <source media="(max-width: 768px)" srcSet={MedIconRetina} />
                    <img src={MedIcon} alt="ZooPharm" />
                  </picture>
                  <Card>
                    <p className="c-result-item__title">{name}</p>
                    {presentation && <p className="c-result-item__subtitle">{presentation}</p>}
                    {(productCategory || releaseCategory) && (
                      <ul className="c-result-item__details">
                        {/* {productCategory && <li>{productCategory}</li>} */}
                        {releaseCategory && <li>{releaseCategory}</li>}
                      </ul>
                    )}
                  </Card>
                </div>
                {moreInformation && (
                  <p
                    style={{ marginTop: '5px', fontSize: '0.9em', textAlign: 'center' }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: moreInformation }}
                  />
                )}
              </GridItem>

              <GridItem md={7}>
                <div className="c-card-plain c-card-plain--green">
                  <p>
                    More information about ZooPharm
                    {"'"}
                    s medications
                    <br />
                    in our Portal
                  </p>
                  <div className="btn-container">
                    <a
                      href={`${siteMetadata.portalUrl}/medications/view/${strapiId}`}
                      className="c-button--primary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      More Information
                    </a>
                  </div>
                </div>
              </GridItem>
            </Grid>
            <Grid>
              <GridItem>
                {shortDescription && (
                  <section
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: shortDescription }}
                    className="medication-detail"
                  />
                )}
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </section>
    </Layout>
  );
};

MedicationTemplate.propTypes = propTypes;
export default MedicationTemplate;

export const query = graphql`
  query MedicationTemplate($id: String!) {
    strapiMedication(id: { eq: $id }) {
      name
      strapiId
      deaSchedule
      presentation
      productCategory
      releaseCategory
      shortDescription
      moreInformation
    }

    allStrapiMedication(sort: { fields: name, order: ASC }) {
      edges {
        node {
          name
          slug
          presentation
        }
      }
    }

    site {
      siteMetadata {
        portalUrl
      }
    }
  }
`;
